import React, { createContext, useContext, useMemo, useState } from 'react'

import { getTheme } from '@/shared/styles/getTheme'
import { getComponentOverrides, getDesignTokens } from '@/shared/styles/themes'
import { createTheme, PaletteMode, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material'

export type GlobalThemeProviderProps = {
  children: React.ReactNode
}


export type ColorModeContextType = {
  toggleColorMode: () => void,
  setColorMode: React.Dispatch<React.SetStateAction<PaletteMode>>,
  mode: PaletteMode
}


export const ColorModeContext = createContext<ColorModeContextType>({
  toggleColorMode: () => { },
  setColorMode: () => { },
  mode: 'light'
})

export const useColorModeContext = () => {
  const context = useContext(ColorModeContext)

  if (context === undefined) {
    throw new Error(
      'useColorModeContext must be used within a GlobalThemeProvider',
    )
  }

  return context
}

export const GlobalThemeProvider: React.FC<GlobalThemeProviderProps> = ({ children }) => {
  const [mode, setColorMode] = useState<PaletteMode>('light')

  const colorMode = {
    toggleColorMode: () => {
      setColorMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'))
    },
    setColorMode,
    mode
  }

  const theme: Theme | undefined = useMemo(() => {
    const whiteLabelThemeOptions = getTheme(mode)
    const designTokens = getDesignTokens(whiteLabelThemeOptions)
    const baseTheme = createTheme(designTokens)
    const globalComponentOverwrite = getComponentOverrides(baseTheme)

    return createTheme(designTokens, {
      components: globalComponentOverwrite,
    })
  }, [mode])

  return (
    <ColorModeContext.Provider value={colorMode}>
      {theme && (
        <>
          <link rel="stylesheet" href="fonts/fonts.css" />
          <MuiThemeProvider theme={theme}>
            {children}
          </MuiThemeProvider>
        </>
      )}
    </ColorModeContext.Provider>
  )
}

