import { ColorPalette } from 'types/muiTheme'

export const lightTheme: ColorPalette = {
  mode: 'light',
  primary: {
    main: '#3091d1',
  },
  text: {
    primary: '#242829',
    dim: 'rgba(36, 40, 41, 0.9)',
    secondary: '#4c4e52',
    disabled: '#6A6A6A',
  },
  secondary: {
    main: '#66C2C4',
  },
  common: {
    white: '#FFFFFF',
  },
  link: {
    color: '#1b5aa6',
  },
  action: {
    hover: 'rgba(0, 0, 0, 0.12)',
    focus: 'rgba(0, 0, 0, 0.12)',
  },
  shadows: {
    0: 'none',
    1: '0 0px 6px rgba(0, 0, 0, 0.1)',
  },
  background: {
    default: '#F7F7F8',
    paper: '#FDFDFD',
  },
  divider: '#D8D8D8',
}
