import { Outlet } from 'react-router-dom'

import { Container, Grid2, styled, Typography } from '@mui/material'

import { StyledNavigationAppBar, StyledNavigationContainer } from './StyledNavigationBar'

export type LayoutProps = {
  //
}

export const Layout: React.FC<LayoutProps> = () => {
  return (
    <>
      <StyledNavigationAppBar position="sticky">
        <StyledNavigationContainer maxWidth="xl" id="primaryNav">
          <Grid2 container justifyContent={'space-between'} alignItems={'center'}>
            <StyledHeaderTypography variant="body1">
              ALYSSA XIONG
            </StyledHeaderTypography>
            <Grid2>
              {/* <NavLinks>
                <NavLink to="/projects">PROJECTS</NavLink>
                <NavLink to="/resume">RESUME</NavLink>
                <IconButton onClick={toggleColorMode}>
                  <Icon
                    icon={isDarkTheme ? faSun : faMoon}
                    sx={{ fontSize: pxToRem(16) }}
                  />
                </IconButton>
              </NavLinks> */}
            </Grid2>
          </Grid2>
        </StyledNavigationContainer>
      </StyledNavigationAppBar>
      <Outlet />
    </>
  )
}

export const StyledContainer = styled(Container)`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.palette.background.default};
`

const StyledHeaderTypography = styled(Typography)`
  font-weight: 500;
`
