
export type AnimationKey = 'idle' | 'walk' | 'crouch' | 'jump' | 'turn' | 'lightpunch' | 'burstyjump' | 'lightattack';


export interface AnimationConfig {
  name: string;
  frames: number;
  frameWidth: number;
  frameHeight: number;
  frameTime: number;
}

// Update the ANIMATIONS type
export const ANIMATIONS: Record<AnimationKey, AnimationConfig> = {
  idle: {
    name: 'processed_idle',
    frames: 4,
    frameWidth: 95,
    frameHeight: 94,
    frameTime: 190
  },
  walk: {
    name: 'processed_walk',
    frames: 6,
    frameWidth: 72,
    frameHeight: 94,
    frameTime: 200,
  },
  crouch: {
    name: 'processed_crouch',
    frames: 3,
    frameWidth: 69,
    frameHeight: 90,
    frameTime: 130,
  },
  turn: {
    name: 'processed_turn',
    frames: 1,
    frameWidth: 62,
    frameHeight: 95,
    frameTime: 50,
  },
  lightpunch: {
    name: 'processed_lightpunch',
    frames: 3,
    frameWidth: 79,
    frameHeight: 86,
    frameTime: 200,
  },
  jump: {
    name: 'processed_jump',
    frames: 11,
    frameWidth: 80,
    frameHeight: 100,
    frameTime: 40,
  },
  burstyjump: {
    name: 'processed_burstyjump',
    frames: 10,
    frameWidth: 99,
    frameHeight: 99,
    frameTime: 140,
  },
  lightattack: {
    name: 'processed_lightattack',
    frames: 10,
    frameWidth: 126,
    frameHeight: 90,
    frameTime: 80,
  },
};
