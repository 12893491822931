import deepmerge from 'deepmerge'

import { Theme, ThemeOptions } from '@mui/material'

const fontSize = 16
const htmlFontSize = 16

// Generate the application theme based on the white label theme
export const pxToRem = (size: number) => `${size / htmlFontSize}rem`

export const getDesignTokens = (whiteLabelThemeOptions: ThemeOptions) => {
  return (deepmerge({
    layout: {
      navHeaderHeight: pxToRem(56),
      defaultLeftDrawerWidth: pxToRem(220),
      defaultRightDrawerWidth: pxToRem(260),
      notificationSidebarWidth: pxToRem(480),

      navSidebarWidth: pxToRem(230),
      navSidebarCollapsedWidth: pxToRem(56),
      navSidebarTransitionSpeed: 250,

      widgetToolbarHeight: pxToRem(48),

      scrollbarWidth: '16px',
    },
    typography: {
      fontSize,
      htmlFontSize,
      pxToRem: (size: number) => pxToRem(size),
      spacing: (factor: number) => pxToRem(factor * 10),
      h1: {
        fontSize: pxToRem(24),
        fontWeight: 400,
        color: whiteLabelThemeOptions.palette?.text?.primary,
      },
      h2: {
        fontSize: pxToRem(14),
        fontWeight: 500,
        color: whiteLabelThemeOptions.palette?.text?.primary,
        textTransform: 'uppercase',
        fontFeatureSettings: '\'ss05\'',
      },
      body1: {
        fontSize: pxToRem(13),
        color: whiteLabelThemeOptions.palette?.text?.primary,
        fontWeight: 400,
        letterSpacing: '0.03em',
      },
      body2: {
        fontSize: pxToRem(13),
        color: whiteLabelThemeOptions.palette?.text?.primary,
        fontWeight: 500,
        letterSpacing: '0.03em',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  }, whiteLabelThemeOptions))
}


export const getComponentOverrides = (theme: Theme) => ({
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: pxToRem(12),
        fontWeight: 600,
        position: 'relative',
        marginBottom: pxToRem(3),
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        paddingLeft: `${pxToRem(16)} !important`,
        paddingRight: `${pxToRem(16)} !important`,

        [theme.breakpoints.up('sm')]: {
          paddingLeft: `${pxToRem(36)} !important`,
          paddingRight: `${pxToRem(36)} !important`,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: '0 5px 30px rgba(0, 0, 0, 0.15)',
      },
    },
  },
  TableHead: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        fontSize: pxToRem(14),
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      text: {
        fontSize: theme.typography.pxToRem(14),
        textTransform: 'capitalize',
        letterSpacing: '0.04rem',
        borderRadius: theme.typography.pxToRem(4),
        fontWeight: 500,
        color: theme.palette.text.primary,
        backgroundColor: 'unset',
        position: 'relative', // Required for absolute positioning of pseudo-element
        
        '& .MuiButton-startIcon': {
          marginRight: theme.typography.pxToRem(4),
        },
        
        '&:hover': {
          color: theme.palette.text.primary,
          backgroundColor: 'unset',
        },
        
        '&:active': {
          color: theme.palette.text.primary,
          backgroundColor: 'unset',
        },
        
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: theme.typography.pxToRem(4),
          backgroundColor: theme.palette.action.hover,
          opacity: 0,
          transform: 'scaleX(1) scaleY(1) perspective(1px)',
          transition: 'transform 0.1s cubic-bezier(0.35, 0.12, 0.14, 1.4), opacity 0.1s ease',
        },
        
        '&:hover::after': {
          opacity: 1,
          backgroundColor: theme.palette.action.hover,
          transform: 'scaleX(1) scaleY(1) perspective(1px)',
        },
        
        '&:active::after': {
          transform: 'scaleX(0.95) scaleY(0.95) perspective(1px)',
        },
      },
    },
  },
})

export const theme = getDesignTokens({})