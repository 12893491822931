import { createBrowserRouter } from 'react-router-dom'

import { HomePage } from '@/pages/HomePage'

import { Layout } from './layouts/Layout'

export const AppRouter = () =>
  createBrowserRouter([
    {
      element: Layout({}),
      errorElement: <div>error</div>,
      children: [
        {
          path: '/',
          element: <HomePage />,
        },
        // {
        //   path: '/',
        //   element: <ProjectsPage />,
        // },
      ],
    },
  ], {
    future: {
      v7_partialHydration: false,
      v7_skipActionErrorRevalidation: false,
      v7_normalizeFormMethod: false,
      v7_fetcherPersist: false
    },
  })