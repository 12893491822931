import React, { useEffect, useRef } from 'react'

interface TornadoCenter {
  x: number
  y: number
  targetX: number
  mergeTime: number
  intensity: number
  startTime: number  // Added to track when merging should begin
}
interface TornadoLine {
  canvas: HTMLCanvasElement
  y: number
  baseRadius: number
  maxRadius: number
  currentRadius: number
  angle: number
  rotationSpeed: number
  lineLength: number
  alpha: number
  verticalSpeed: number
  swayOffset: number
  tornadoIndex: number
  scale: number
  intensity: number
  currentCenterX: number
  xOffset: number  // Changed from optional to required

  reset(): void
  update(time: number, centers: TornadoCenter[]): void
  draw(ctx: CanvasRenderingContext2D): void
}

class TornadoLineImpl implements TornadoLine {
  canvas: HTMLCanvasElement
  y: number = 0
  baseRadius: number = 0
  maxRadius: number = 0
  currentRadius: number = 0
  angle: number = 0
  rotationSpeed: number = 0
  lineLength: number = 0
  alpha: number = 0
  verticalSpeed: number = 0
  swayOffset: number = 0
  tornadoIndex: number = 0
  scale: number = 0
  intensity: number = 1
  currentCenterX: number = 0
  xOffset: number = 0  // Added explicit property with initialization

  constructor(canvas: HTMLCanvasElement, tornadoIndex: number, intensity: number) {
    this.canvas = canvas
    this.tornadoIndex = tornadoIndex
    this.intensity = intensity
    this.reset()
  }

  reset(): void {
    this.y = this.canvas.height
    this.baseRadius = Math.random() * 8 + 2
    this.maxRadius = Math.random() * (120 * this.intensity) + (60 * this.intensity)
    this.currentRadius = this.baseRadius
    this.angle = Math.random() * Math.PI * 2
    this.rotationSpeed = (Math.random() * 0.02 + 0.01) * this.intensity
    this.lineLength = (Math.random() * 40 + 20) * this.intensity
    this.alpha = (Math.random() * 0.4 + 0.2) * this.intensity
    this.verticalSpeed = (Math.random() * 1.2 + 0.4) * this.intensity
    this.swayOffset = Math.random() * Math.PI * 2
    this.scale = 0.6
    this.xOffset = 0  // Reset xOffset
  }

  update(time: number, centers: TornadoCenter[]): void {
    this.y -= this.verticalSpeed
    const progress = 1 - (this.y / this.canvas.height)

    // Get primary center for this line
    const primaryCenter = centers[this.tornadoIndex]

    // Calculate merge influence
    let finalCenterX = primaryCenter.x
    if (time >= primaryCenter.startTime) {
      const mergeProgress = Math.min(1, (time - primaryCenter.startTime) / primaryCenter.mergeTime)
      finalCenterX = primaryCenter.x + (primaryCenter.targetX - primaryCenter.x) * mergeProgress

      // Add influence from other tornados during merge
      if (mergeProgress > 0) {
        centers.forEach((center, idx) => {
          if (idx !== this.tornadoIndex && time >= center.startTime) {
            const otherMergeProgress = Math.min(1, (time - center.startTime) / center.mergeTime)
            const influence = otherMergeProgress * 0.3
            finalCenterX += (center.x + (center.targetX - center.x) * otherMergeProgress) * influence
          }
        })
      }
    }

    this.currentCenterX = finalCenterX

    const heightFactor = Math.sin(progress * Math.PI)
    this.currentRadius = (this.baseRadius + (this.maxRadius - this.baseRadius) *
      (progress + heightFactor * 0.7)) * this.scale

    if (progress > 0.85) {
      this.alpha *= 0.95
      this.lineLength *= 0.97
    }

    const swayAmount = 80 * this.scale * this.intensity
    const verticalSwayFactor = Math.sin(progress * Math.PI * 2)
    const swaySpeed = 0.0004 * this.intensity
    const horizontalSway = Math.sin(time * swaySpeed + this.swayOffset) * swayAmount
    const secondarySway = Math.cos(time * swaySpeed * 0.7 + this.swayOffset) * (swayAmount * 0.5)

    this.xOffset = (horizontalSway + secondarySway) * verticalSwayFactor

    this.angle += this.rotationSpeed

    if (this.y < 0 || this.alpha < 0.01) {
      this.reset()
    }
  }

  draw(ctx: CanvasRenderingContext2D): void {
    const startAngle = this.angle
    const endAngle = startAngle + (this.lineLength / this.currentRadius)

    ctx.beginPath()

    const baseHue = 270 + (this.tornadoIndex * 20)
    const hue = baseHue + (this.intensity * 15)
    ctx.strokeStyle = `hsla(${hue}, 65%, 56%, ${this.alpha})`
    ctx.lineWidth = (1.5 + (this.lineLength / 40)) * this.scale

    for (let a = startAngle; a <= endAngle; a += 0.02) {
      const x = this.currentCenterX + this.xOffset + Math.cos(a) * this.currentRadius  // Removed optional chaining
      const y = this.y * 0.85 + Math.sin(a) * (this.currentRadius * 0.15)

      if (a === startAngle) {
        ctx.moveTo(x, y)
      } else {
        ctx.lineTo(x, y)
      }
    }

    ctx.stroke()

    if (this.lineLength > 30) {
      ctx.shadowBlur = 10 * this.scale * this.intensity
      ctx.shadowColor = `hsla(${hue}, 65%, 56%, 0.5)`
      ctx.stroke()
      ctx.shadowBlur = 0
    }
  }
}

export const SwayingTornadoScene: React.FC<{ canvasRef: React.RefObject<HTMLCanvasElement> }> = ({ canvasRef }) => {
  const linesRef = useRef<TornadoLine[]>([])
  const tornadoCentersRef = useRef<TornadoCenter[]>([])

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    const resizeCanvas = () => {
      if (!canvas) return
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
      initializeTornadoCenters()
    }

    const initializeTornadoCenters = () => {
      if (!canvas) return

      // Create three distinct tornados
      tornadoCentersRef.current = [
        {
          x: canvas.width * 0.25,
          y: canvas.height,
          targetX: canvas.width * 0.4,
          mergeTime: 3000,
          startTime: 5000,  // Start merging after 5 seconds
          intensity: 1.2
        },
        {
          x: canvas.width * 0.45,
          y: canvas.height,
          targetX: canvas.width * 0.4,
          mergeTime: 3000,
          startTime: 5000,
          intensity: 0.8
        },
        {
          x: canvas.width * 0.65,
          y: canvas.height,
          targetX: canvas.width * 0.4,
          mergeTime: 3000,
          startTime: 7000,  // This one starts merging later
          intensity: 1.0
        }
      ]

      // Create lines for each tornado
      const linesPerTornado = 100
      linesRef.current = []

      tornadoCentersRef.current.forEach((center, idx) => {
        for (let i = 0; i < linesPerTornado; i++) {
          linesRef.current.push(new TornadoLineImpl(canvas, idx, center.intensity))
        }
      })
    }

    resizeCanvas()
    window.addEventListener('resize', resizeCanvas)

    let startTime = 0
    let animationFrameId: number

    const animate = (timestamp: number) => {
      if (!ctx || !canvas) return

      if (startTime === 0) startTime = timestamp
      const elapsedTime = timestamp - startTime

      ctx.fillStyle = '#000000'
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      // Draw moon
      const moonX = canvas.width * 0.85
      const moonY = canvas.height * 0.15
      const moonRadius = 100

      ctx.save()
      const moonGradient = ctx.createRadialGradient(
        moonX, moonY, 0,
        moonX, moonY, moonRadius
      )
      moonGradient.addColorStop(0, 'rgba(255, 255, 250, 1)')
      moonGradient.addColorStop(0.5, 'rgba(255, 255, 250, 0.8)')
      moonGradient.addColorStop(1, 'rgba(255, 255, 250, 0)')

      ctx.beginPath()
      ctx.fillStyle = moonGradient
      ctx.arc(moonX, moonY, moonRadius, 0, Math.PI * 2)
      ctx.fill()

      ctx.shadowColor = 'rgba(255, 255, 250, 0.8)'
      ctx.shadowBlur = 70
      ctx.beginPath()
      ctx.arc(moonX, moonY, moonRadius - 15, 0, Math.PI * 2)
      ctx.fill()
      ctx.restore()

      ctx.globalCompositeOperation = 'screen'
      linesRef.current.forEach(line => {
        line.update(elapsedTime, tornadoCentersRef.current)
        line.draw(ctx)
      })
      ctx.globalCompositeOperation = 'source-over'

      animationFrameId = requestAnimationFrame(animate)
    }

    animate(0)

    return () => {
      window.removeEventListener('resize', resizeCanvas)
      cancelAnimationFrame(animationFrameId)
    }
  }, [])

  return null
}

export default SwayingTornadoScene