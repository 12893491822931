import { useRef } from 'react'

import { SwayingTornadoScene } from '@/components/DreamScene/SwayingTornadoScene'
import { GundamWing } from '@/components/GundamWing/GundamWing'

export const HomePage: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  return (
    <>
      {/* <SwayingTornadoScene /> */}
      {/* <GundamBattleScene /> */}
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 'calc(100vh - 64px)',
          overflow: 'hidden',
        }}
      >
        <canvas
          ref={canvasRef}
          style={{
            width: '100vw',
            height: 'calc(100vh - 64px)',
            backgroundColor: 'black',
            overflow: 'hidden',
          }}
          width={window.innerWidth}
          height={'100%'}
        />
        <div className="controls-info" style={{
          position: 'fixed',
          top: 60,
          left: 20,
          color: 'white',
          fontFamily: 'monospace',
        }}>
          Controls:
          <br />← → : Move
          <br />↓ : Crouch
          <br />Space : Jump
          <br />Shift + Space: Bursty Jump
          <br />Z : Light Punch
          <br />X : Light Attack
        </div>
      </div>

      <GundamWing canvasRef={canvasRef} />
      <SwayingTornadoScene canvasRef={canvasRef} />
    </>
  )
}