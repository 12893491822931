import { ColorPalette } from 'types/muiTheme'

export const darkTheme: ColorPalette = {
  mode: 'dark',
  primary: {
    main: '#3988bd',
  },
  secondary: {
    main: '#66C2C4',
  },
  text: {
    primary: '#DfE5E6',
    dim: 'rgba(255, 255, 255, 0.7)',
    secondary: '#B0B0B0',
    disabled: '#6A6A6A',
  },
  common: {
    white: '#FFFFFF',
  },
  link: {
    color: '#78b6ff',
  },
  action: {
    hover: 'rgba(255, 255, 255, 0.15)',
    focus: 'rgba(255, 255, 255, 0.15)',
  },
  shadows: {
    0: 'none',
    1: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  },
  background: {
    default: '#0F1011',
    paper: '#232325',
  },
  divider: '#484848',
}
