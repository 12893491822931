import React, { useEffect, useRef, useState } from 'react'

import { AnimationConfig, AnimationKey, ANIMATIONS } from './animations'

interface GundamState {
  currentAction: AnimationKey
  previousAction: AnimationKey
  currentFrame: number
  frameTimer: number
  position: {
    x: number
    y: number
    velocityX: number
    velocityY: number
    direction: 1 | -1
  }
  isAirborne: boolean
  isTurning: boolean
  isAttacking: boolean
  attackCooldown: number
  jumpStartY: number
  jumpPhase: 'crouch' | 'rise' | 'fall' | 'impact' | 'recovery' | 'none'
  isCrouching: boolean
}

const SPRITE_SCALE = 2
const MOVEMENT_SPEED = 2.25
const REGULAR_JUMP_HEIGHT = 200
const BURSTY_JUMP_HEIGHT = 300
const ATTACK_COOLDOWN = 400
const GROUND_Y = 200

// Jump timing constants
const CROUCH_FRAME_DURATION = 100
const AIR_FRAME_DURATION = 100
const IMPACT_FRAME_DURATION = 50
const RECOVERY_FRAME_DURATION = 100

export type GundamWingProps = {
  canvasRef: React.RefObject<HTMLCanvasElement>
}

export const GundamWing: React.FC<GundamWingProps> = ({ canvasRef }) => {
  const [pressedKeys, setPressedKeys] = useState<Set<string>>(new Set())
  const spritesRef = useRef<Record<string, HTMLImageElement>>({})
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(0)

  const gundamStateRef = useRef<GundamState>({
    currentAction: 'idle',
    previousAction: 'idle',
    currentFrame: 0,
    frameTimer: 0,
    position: {
      x: window.innerWidth / 2,
      y: window.innerHeight - GROUND_Y,
      velocityX: 0,
      velocityY: 0,
      direction: 1
    },
    isAirborne: false,
    isTurning: false,
    isAttacking: false,
    attackCooldown: 0,
    jumpStartY: 0,
    jumpPhase: 'none',
    isCrouching: false
  })

  useEffect(() => {
    let loadedCount = 0
    const totalImages = Object.keys(ANIMATIONS).length
    const animationKeys = Object.keys(ANIMATIONS) as AnimationKey[]

    const loadImage = (action: string, config: AnimationConfig) => {
      return new Promise<void>((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          spritesRef.current[action] = img
          loadedCount++
          setLoadingProgress((loadedCount / totalImages) * 100)
          resolve()
        }
        img.onerror = () => reject(new Error(`Failed to load ${config.name}`))
        img.src = `/winggundam/${config.name}.png`
      })
    }

    Promise.all(
      animationKeys.map(key => loadImage(key, ANIMATIONS[key]))
    ).then(() => setImagesLoaded(true))
      .catch(error => console.error('Failed to load images:', error))

    return () => { spritesRef.current = {} }
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      setPressedKeys(prev => new Set(prev).add(e.key))
    }

    const handleKeyUp = (e: KeyboardEvent) => {
      setPressedKeys(prev => {
        const newSet = new Set(prev)
        newSet.delete(e.key)
        return newSet
      })
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const getJumpFrameDuration = (frame: number): number => {
    if (frame <= 3) return CROUCH_FRAME_DURATION
    if (frame <= 5) return AIR_FRAME_DURATION
    if (frame === 6) return IMPACT_FRAME_DURATION
    return RECOVERY_FRAME_DURATION
  }

  const calculateJumpHeight = (frame: number, maxHeight: number): number => {
    if (frame <= 3) return 0
    if (frame === 4) return maxHeight
    if (frame === 5) return maxHeight * 0.5
    return 0
  }

  const updateJumpAnimation = (state: GundamState, nextState: GundamState, deltaTime: number) => {
    const currentFrameDuration = getJumpFrameDuration(state.currentFrame)
    nextState.frameTimer += deltaTime

    if (nextState.frameTimer >= currentFrameDuration) {
      nextState.frameTimer = 0
      
      if (state.currentFrame <= 3) {
        nextState.jumpPhase = 'crouch'
      } else if (state.currentFrame === 4) {
        nextState.jumpPhase = 'rise'
        nextState.isAirborne = true
      } else if (state.currentFrame === 5) {
        nextState.jumpPhase = 'fall'
      } else if (state.currentFrame === 6) {
        nextState.jumpPhase = 'impact'
        nextState.isAirborne = false
      } else {
        nextState.jumpPhase = 'recovery'
      }

      nextState.currentFrame++
      
      if (nextState.currentFrame >= ANIMATIONS[state.currentAction].frames) {
        nextState.currentFrame = 0
        nextState.currentAction = 'idle'
        nextState.jumpPhase = 'none'
        return
      }
    }

    const maxHeight = state.currentAction === 'burstyjump' ? BURSTY_JUMP_HEIGHT : REGULAR_JUMP_HEIGHT
    const height = calculateJumpHeight(nextState.currentFrame, maxHeight)
    nextState.position.y = nextState.jumpStartY - height
  }

  const updateState = (deltaTime: number) => {
    const state = gundamStateRef.current
    const nextState = { ...state }

    // Update attack cooldown
    if (state.attackCooldown > 0) {
      nextState.attackCooldown = Math.max(0, state.attackCooldown - deltaTime)
    }

    // Handle turning and movement
    const wantsToMoveRight = pressedKeys.has('ArrowRight')
    const wantsToMoveLeft = pressedKeys.has('ArrowLeft')
    const needsToTurn = (wantsToMoveRight && state.position.direction === -1) ||
      (wantsToMoveLeft && state.position.direction === 1)

    // Handle movement and actions
    if (!state.isAttacking && state.jumpPhase === 'none') {
      // Handle crouching
      if (pressedKeys.has('ArrowDown') && !state.isAirborne) {
        if (!state.isCrouching) {
          nextState.currentAction = 'crouch'
          nextState.isCrouching = true
          if (state.currentFrame < 1) {
            nextState.frameTimer += deltaTime
            if (nextState.frameTimer >= ANIMATIONS.crouch.frameTime) {
              nextState.frameTimer = 0
              nextState.currentFrame = 1
            }
          }
        } else {
          // Hold frame 1 while crouching
          nextState.currentFrame = 1
        }
        nextState.position.velocityX = 0
      } else if (state.isCrouching) {
        // Complete crouch animation when key is released
        nextState.frameTimer += deltaTime
        if (nextState.frameTimer >= ANIMATIONS.crouch.frameTime) {
          nextState.frameTimer = 0
          nextState.currentFrame++
          if (nextState.currentFrame >= ANIMATIONS.crouch.frames) {
            nextState.currentFrame = 0
            nextState.currentAction = 'idle'
            nextState.isCrouching = false
          }
        }
      } else if (!state.isCrouching) {
        // Normal movement when not crouching
        if (needsToTurn) {
          if (!state.isTurning) {
            nextState.isTurning = true
            nextState.currentAction = 'turn'
            nextState.currentFrame = 0
            nextState.frameTimer = 0
            nextState.position.velocityX = 0
          }
        } else if (!state.isTurning) {
          if (wantsToMoveRight) {
            nextState.position.velocityX = MOVEMENT_SPEED
            nextState.position.direction = 1
            nextState.currentAction = state.isAirborne ? state.currentAction : 'walk'
          } else if (wantsToMoveLeft) {
            nextState.position.velocityX = -MOVEMENT_SPEED
            nextState.position.direction = -1
            nextState.currentAction = state.isAirborne ? state.currentAction : 'walk'
          } else {
            nextState.position.velocityX = 0
            if (!state.isAirborne) {
              nextState.currentAction = 'idle'
            }
          }
        }

        // Start jump if space is pressed
        if (pressedKeys.has(' ') && !state.isAirborne) {
          const isBurstyJump = pressedKeys.has('Shift')
          nextState.currentAction = isBurstyJump ? 'burstyjump' : 'jump'
          nextState.jumpStartY = state.position.y
          nextState.currentFrame = 0
          nextState.frameTimer = 0
          nextState.jumpPhase = 'crouch'
        }

        // Handle attacks
        if (state.attackCooldown === 0) {
          if (pressedKeys.has('z')) {
            nextState.currentAction = 'lightpunch'
            nextState.isAttacking = true
            nextState.currentFrame = 0
            nextState.frameTimer = 0
            nextState.attackCooldown = ATTACK_COOLDOWN
            nextState.position.velocityX = 0
          } else if (pressedKeys.has('x')) {
            nextState.currentAction = 'lightattack'
            nextState.isAttacking = true
            nextState.currentFrame = 0
            nextState.frameTimer = 0
            nextState.attackCooldown = ATTACK_COOLDOWN
            nextState.position.velocityX = 0
          }
        }
      }
    }

    // Update jump animation if in progress
    if (state.jumpPhase !== 'none') {
      updateJumpAnimation(state, nextState, deltaTime)
    }

    // Update position if not attacking and not crouching
    if (!state.isAttacking && !state.isCrouching) {
      nextState.position.x += nextState.position.velocityX
      nextState.position.x = Math.max(50, Math.min(window.innerWidth - 50, nextState.position.x))
    }

    // Update non-jump animations for non-crouch states
    if (state.jumpPhase === 'none' && !state.isCrouching) {
      const animation = ANIMATIONS[nextState.currentAction]
      nextState.frameTimer += deltaTime
      
      if (nextState.frameTimer >= animation.frameTime) {
        nextState.frameTimer = 0
        nextState.currentFrame++

        if (nextState.currentFrame >= animation.frames) {
          if (nextState.isAttacking) {
            nextState.isAttacking = false
            nextState.currentAction = 'idle'
          }
          if (nextState.isTurning) {
            nextState.isTurning = false
            nextState.position.direction = nextState.position.direction === 1 ? -1 : 1

            if ((nextState.position.direction === 1 && wantsToMoveRight) ||
              (nextState.position.direction === -1 && wantsToMoveLeft)) {
              nextState.currentAction = 'walk'
              nextState.position.velocityX = nextState.position.direction * MOVEMENT_SPEED
            } else {
              nextState.currentAction = 'idle'
              nextState.position.velocityX = 0
            }
          }
          nextState.currentFrame = 0
        }
      }
    }

    gundamStateRef.current = nextState
  }

  const draw = (ctx: CanvasRenderingContext2D) => {
    if (!imagesLoaded) return

    const state = gundamStateRef.current
    const animation = ANIMATIONS[state.currentAction]
    const sprite = spritesRef.current[state.currentAction]

    if (!sprite || !sprite.complete) return

    ctx.save()
    ctx.translate(state.position.x, state.position.y)
    ctx.scale(state.position.direction * SPRITE_SCALE, SPRITE_SCALE)

    ctx.drawImage(
      sprite,
      state.currentFrame * animation.frameWidth,
      0,
      animation.frameWidth,
      animation.frameHeight,
      -animation.frameWidth / 2,
      -animation.frameHeight,
      animation.frameWidth,
      animation.frameHeight
    )

    ctx.restore()
  }

  useEffect(() => {
    if (!imagesLoaded) return

    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    let lastTime = 0
    let animationFrameId: number

    const animate = (timestamp: number) => {
      const deltaTime = timestamp - lastTime
      lastTime = timestamp

      if (canvas.width !== window.innerWidth || canvas.height !== window.innerHeight) {
        canvas.width = window.innerWidth
        canvas.height = window.innerHeight
      }

      updateState(deltaTime)
      draw(ctx)

      animationFrameId = requestAnimationFrame(animate)
    }

    animate(0)

    return () => {
      cancelAnimationFrame(animationFrameId)
    }
  }, [imagesLoaded, pressedKeys])

  if (!imagesLoaded) {
    return (
      <div style={{
        width: '100vw',
        height: 'calc(100vh - 48px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        color: 'white',
        flexDirection: 'column',
        gap: '20px'
      }}>
        <div>Loading Gundam Assets...</div>
        <div style={{
          width: '200px',
          height: '20px',
          border: '1px solid white',
          borderRadius: '10px',
          overflow: 'hidden'
        }}>
          <div style={{
            width: `${loadingProgress}%`,
            height: '100%',
            backgroundColor: '#9333ea',
            transition: 'width 0.3s ease'
          }} />
        </div>
        <div>{Math.round(loadingProgress)}%</div>
      </div>
    )
  }

  return null
}