import { darkTheme } from '@/shared/styles/darkTheme'
import { lightTheme } from '@/shared/styles/lightTheme'
import { PaletteMode, ThemeOptions } from '@mui/material'

const fontFamily = [
  'Space Grotesk',
  'Geist',
  'Inter',
  'Roboto',
  '"Helvetica Neue"',
  'Helvetica',
  '"Segoe UI"',
  'Arial',
  'sans-serif',
].join(',')

export const getTheme = (mode: PaletteMode): ThemeOptions => {
  return ({
    typography: {
      fontFamily,
    },
    palette: {
      mode,
      ...(mode === 'light' ? lightTheme : darkTheme),
    },
  })
}
