import { AppBar, Container, Grid2, styled } from '@mui/material'

export const StyledNavigationAppBar = styled(AppBar)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: ${props => props.theme.palette.text.primary};
  height: ${props => props.theme.typography.pxToRem(40)};
  justify-content: center;
  border-bottom: 1px solid ${props => props.theme.palette.divider};
  padding-right: 0 !important;
  box-shadow: none;
  
  ${props => props.theme.breakpoints.up('md')} {
    height: ${props => props.theme.typography.pxToRem(48)};
  }
`

export const StyledNavigationContainer = styled(Container)`
`

export const StyledLogoWrapper = styled(Grid2)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: ${props => props.theme.typography.pxToRem(12)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-right: ${props => props.theme.typography.pxToRem(16)};
  }
  
  ${props => props.theme.breakpoints.up('md')} {
    margin-right: ${props => props.theme.typography.pxToRem(24)};
  }
`

export const StyledNavSearchContainer = styled(Grid2)`
  flex: 1;
  margin-right: ${props => props.theme.typography.pxToRem(8)};
  
  ${props => props.theme.breakpoints.up('md')} {
    margin-right: ${props => props.theme.typography.pxToRem(16)};
  }
`
