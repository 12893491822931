

import { RouterProvider } from 'react-router-dom'

import { AppRouter } from './AppRouter'
import { GlobalThemeProvider } from './providers'

export const App: React.FC = () => {

  return (
    <div style={{ overflowX: 'hidden', width: '100%', height: '100%' }}>
      <GlobalThemeProvider>
        <RouterProvider router={AppRouter()} />
      </GlobalThemeProvider>
    </div>
  )
}

